import classNames from "classnames";
import React from "react";
import {
  PolymorphicForwardRefExoticComponent,
  PolymorphicPropsWithoutRef,
} from "react-polymorphic-types";

const classNamePrefix = "Button";

type ButtonOwnProps = {
  disabled?: boolean;
  fluid?: boolean;
  icon?: "cancel" | "download" | "link" | "search" | "see" | "send";
  isSubmitting?: boolean;
  spinnerLabel?: string;
  variant?: "secondary";
};
const buttonDefaultElement = "button";

export const Button: PolymorphicForwardRefExoticComponent<
  ButtonOwnProps,
  typeof buttonDefaultElement
> = React.forwardRef(
  <TElementType extends React.ElementType = typeof buttonDefaultElement>(
    props: PolymorphicPropsWithoutRef<ButtonOwnProps, TElementType>,
    ref?: React.ForwardedRef<HTMLButtonElement>,
  ) => {
    const {
      as: Tag = buttonDefaultElement,
      children,
      className,
      disabled,
      fluid,
      icon,
      isSubmitting,
      spinnerLabel = "Merci de patienter…",
      variant,
      ...restProps
    } = props;
    return (
      <Tag
        ref={ref}
        {...restProps}
        className={classNames(className, classNamePrefix)}
        data-fluid={fluid}
        data-icon={icon}
        data-is-submitting={isSubmitting}
        data-variant={variant}
        disabled={isSubmitting ? isSubmitting : disabled}
      >
        {isSubmitting && (
          <span
            aria-label={spinnerLabel}
            className={`${classNamePrefix}-spinner`}
            role="status"
          />
        )}
        {children}
      </Tag>
    );
  },
);
