import { useTranslation } from "react-i18next";

import { Button } from "../../shared/Button";

export const ErrorsReloadButton = () => {
  const { t } = useTranslation();
  const { label } = t("errors.shared.ErrorsReloadButton") as unknown as {
    label: string;
  };

  return (
    <Button icon="link" type="button" onClick={() => window.location.reload()}>
      {label}
    </Button>
  );
};
